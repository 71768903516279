import { GetServerSideProps } from 'next';
import { getServerSideSitemap, ISitemapField } from 'next-sitemap';
import { generateNavigation } from '../../constants/navItems';

interface SlugsDataI {
  data: { slugs: { slug: string }[] };
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const localeData = await import(
    `../../localization/languages/${ctx?.locale ?? 'en'}.ts`
  );

  let resultantData: string[] = [];
  let fields: ISitemapField[] = [];
  const navigationItems = generateNavigation(localeData.default);
  navigationItems?.children?.map((items: any, index: number) => {
    // if (items.url && items.name !== 'store') {
    //   resultantData.push('/store/' + (items.name ?? ''));
    // }
    let result = '';

    if (items.name) {
      if (items.name === 'store') {
        result = items.url;
      } else {
        if (items.url?.includes('?')) {
          result = '/store/' + items.name;
        } else {
          result = items.url ?? '';
        }
      }
    } else {
      result = '';
    }
    resultantData.push(result);
    items?.subDivision &&
      items?.subDivision?.map((subChild: any, ind: number) => {
        subChild?.children &&
          subChild?.children?.map((subChildChildren: any, inx: number) => {
            if (subChildChildren.url) {
              if (subChildChildren.name !== 'h&m-gift-cards') {
                resultantData.push('/store/' + (subChildChildren.name ?? ''));
              } else {
                resultantData.push('/store/' + 'h&amp;m-gift-cards');
              }
            }
          });
      });
  });

  fields = resultantData?.map((cur: any) => ({
    loc: (process.env.NEXT_PUBLIC_SITE_URL || 'https://driffle.com') + `${cur}`,
    lastmod: new Date().toISOString(),
  }));

  return getServerSideSitemap(ctx, fields);
};

export default function Site() {}
